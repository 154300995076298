/**
 * All the global actions that need to be forwarded to Partytown.
 */
export const forwardGoogleGtag = ['dataLayer.push', 'gtag']

export const injectGtagBase = ({ $partytown, $config, $googleAnalyticsConfig }) => {
  if (!$config.enabled) {
    return
  }

  return {
    key: 'google-gtag-base',
    type: $partytown.gtagEnabled ? 'text/partytown' : 'text/javascript',
    src: `https://www.googletagmanager.com/gtag/js?id=${$googleAnalyticsConfig.id}`,
    defer: true,
  }
}

/**
 * Injects the Google GTAG script for Partytown into the head.
 *
 * __dangerouslyDisableSanitizersByTagID is required to tell Nuxt to not escape
 * characters in this block.
 */
export const injectGtag = ({ $partytown, $config, $googleAnalyticsConfig, $googleAdsConfig, $user, $query }) => {
  if (!$config.enabled) {
    return
  }

  return {
    key: 'google-gtag',
    type: $partytown.gtagEnabled ? 'text/partytown' : 'text/javascript',
    innerHTML: injectGtagScript($googleAnalyticsConfig.id, $googleAdsConfig.id, $user?.Id, $user?.Email, $query),
  }
}

/**
 * Generates and returns the actual script content for Google Gtag to be
 * injected.
 */
const injectGtagScript = (id, googleAdsId, userId, userEmail, query) => {
  return `
  window.dataLayer = window.dataLayer || [];
  window.gtag = function () {
    dataLayer.push(arguments);
  };
  window.gtag('set', 'linker', {'domains': ['rvezy.com']});
  window.gtag('js', new Date());
  window.gtag('set', 'user_data', {
    'user_id': '${userId ?? ''}',
    'email': '${userEmail ?? ''}'
  });
  window.gtag('config', '${id}', {
    'user_id': '${userId ?? ''}',
    'campaign_id' : '${query?.rvz_camp_id ?? ''}', // rvz_camp_id
    'user_properties': {
      'user_id_dimension': '${userId ?? ''}'
    }
  });
  window.gtag('config', '${googleAdsId}', {
    'allow_enhanced_conversions': true
  });
  `
}
