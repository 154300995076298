import { COOKIES } from '~/constants'
import isBot from '~/lib/isBot.js'

/**
 * All the global actions that need to be forwarded to Partytown.
 */
export const forwardDatadog = [
  'DD_RUM.version',
  'DD_RUM.onReady',
  'DD_RUM.init',
  'DD_RUM.setGlobalContextProperty',
  'DD_RUM.removeGlobalContextProperty',
  'DD_RUM.getGlobalContext',
  'DD_RUM.setGlobalContext',
  'DD_RUM.clearGlobalContext',
  'DD_RUM.getInternalContext',
  'DD_RUM.getInitConfiguration',
  'DD_RUM.addAction',
  'DD_RUM.addError',
  'DD_RUM.addTiming',
  'DD_RUM.setUser',
  'DD_RUM.getUser',
  'DD_RUM.setUserProperty',
  'DD_RUM.removeUserProperty',
  'DD_RUM.clearUser',
  'DD_RUM.startView',
  'DD_RUM.stopSession',
  'DD_RUM.startSessionReplayRecording',
  'DD_RUM.stopSessionReplayRecording',
  'DD_RUM.addFeatureFlagEvaluation',
  'DD_RUM.getSessionReplayLink',
]

/**
 * Injects the Datadog script for Partytown into the head.
 *
 * __dangerouslyDisableSanitizersByTagID is required to tell Nuxt to not escape
 * characters in this block.
 */
export const injectDatadog = ({ $partytown, $config, $experiment, $cookies, $userAgent }) => {
  const ddEnabled = $experiment.isEnabled('rvz10584DatadogRumEnabled') ?? false

  if (!ddEnabled) {
    return
  }

  return {
    key: 'datadog',
    type: $partytown.datadogEnabled ? 'text/partytown' : 'text/javascript',
    innerHTML: injectDatadogScript($config, $experiment, $cookies, $userAgent),
  }
}

/**
 * Generates and returns the actual script content for DataDog to be injected.
 */
const injectDatadogScript = ($config, $experiment, $cookies, $userAgent) => {
  // TODO: one `experiments` has become a static lookup on the Nuxt instance, we
  // should replace this.
  const ddTrackInteraction = Boolean($experiment.getVariant('rvz10584DatadogRumTrackInteraction')) || false
  const ddSampleRate = parseInt($experiment.getVariant('rvz11609DatadogRumSampleRate')) ?? $config.sessionSampleRate
  const isBotUserAgent = isBot($userAgent)

  const datadogConfig = {
    ...$config,
    trackUserInteractions: ddTrackInteraction,
    sessionSampleRate: isBotUserAgent ? 0 : ddSampleRate,
  }

  return `
  (function(h,o,u,n,d) {
    h=h[d]=h[d]||{q:[],onReady:function(c){h.q.push(c)}}
    d=o.createElement(u);d.async=1;d.src=n
    n=o.getElementsByTagName(u)[0];n.parentNode.insertBefore(d,n)
  })(window,document,'script','https://www.datadoghq-browser-agent.com/us1/v5/datadog-rum-slim.js','DD_RUM')
  window.DD_RUM.onReady(function() {
    window.DD_RUM.setGlobalContextProperty("rtsessionId", "${$cookies.get(COOKIES.session)}");
    window.DD_RUM.init(${JSON.stringify(datadogConfig).replace(/"[*]regex(\/[^/]+?\/[gims]*)"/g, '$1')});
  })
  `
}
